import moment from "moment-timezone";

export const ordinal_suffix_of = (i) => {
    var j = i % 10,
        k = i % 100;
    if (j == 1 && k != 11) {
        return i + "st";
    }
    if (j == 2 && k != 12) {
        return i + "nd";
    }
    if (j == 3 && k != 13) {
        return i + "rd";
    }
    return i + "th";
}


export const isSameDay = unixTimestamp => moment(unixTimestamp * 1000).isSame(moment(), 'day')

export const formatPrice = (value, currency = "") => {
    switch (currency) {
        case "INR":
            return `Rs ${value}`;
        case "AUD":
                return `AU$ ${value}`;
        case "USD":
                return `US$ ${value}`;
        case "QAR":
                return `QAR ${value}`;

        default:
            return `${currency} ${value}`;
    }
}
