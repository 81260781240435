import { Box, Button, Checkbox, CircularProgress, Divider, FormControlLabel, SwipeableDrawer, TextField, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useEffect, useState, useRef } from "react";
import { grey } from '@mui/material/colors';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { useDispatch, useSelector } from 'react-redux';
import { setAssesmentData, setShowUserForm } from '../../redux/actions/commonActions';
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { confirmGuestPhoneNumberOtp, createGuestUserAccount, setShowLoginForm } from '../../redux/actions/authActions';
import { createBooking, createWaitlistBooking, prepareWaitlistBooking } from '../../redux/actions/apiActions';
import { DoubleArrow } from '@mui/icons-material';
import moment from 'moment-timezone';
import { GOOGLE_CAPTCHA_SITE_KEY } from '../../config/auth';
import Recaptcha from 'react-google-invisible-recaptcha';
import OtpInput from '../../components/OtpInput/OtpInput';
import PhoneNumberInput from '../../components/PhoneNumberInput/PhoneNumberInput';
import { Link } from '@mui/material';
import TermsContent from '../TermsAndCondition/TermsContent';
import ScrollDialog from '../../components/Dialog/ScrollDialog';
import parseHtml from 'html-react-parser';


const UserForm = ({ scheduleData }) => {

    const [openDrawer, setOpenDrawer] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [captchaResolved, setCaptchaResolved] = useState(false);
    const [otpFormData, setOtpFormData] = useState(null);
    const [openPopup, setOpenPopup] = useState(false)
    const showUserFormData = useSelector(state => state.common.showUserFormData);
    const orgDetails = useSelector(state => state.api.orgDetails);
    const serviceDetails = useSelector(state => state.api.serviceDetails);
    const productDetails = useSelector(state => state.api.productDetails);
    const userData = useSelector(state => state.auth.userData);
    const assessmentData = useSelector(state => state.common.assessmentData);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const recaptchaRef = useRef(null);
    const { height, width } = useWindowDimensions();

    const toggleDrawer = (newOpen) => () => {
        //setOpenDrawer(newOpen);
        dispatch(setShowUserForm(newOpen));
    };

    useEffect(() => {
        setOpenDrawer(showUserFormData ? true : false)
        if (showUserFormData && recaptchaRef?.current?.execute) {
            recaptchaRef?.current?.execute();
        }
        if (!showUserFormData) {
            setOtpFormData(null);
        }
    }, [showUserFormData])

    const phoneRegExp = /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/
    const userFormSchema = Yup.object({
        customerName: Yup.string().required("Name is required").min(3, "Name should be atleast 3 characters"),
        customerPhone: Yup.string().matches(phoneRegExp, 'Phone number is not valid'),
        customerEmail: Yup.string().email("Invalid Email")
    });

    const createGuetUser = async (payload) => {
        return await dispatch(createGuestUserAccount(payload));
    }

    const createAppointment = async (payload) => {
        return await dispatch(createBooking(payload));
    }
    const prepareQueue = async (payload) => {
        return await dispatch(prepareWaitlistBooking(payload));
    }
    const createQueue = async (payload) => {
        return await dispatch(createWaitlistBooking(payload));
    }

    const completeBookingProcess = async (userDetails) => {
        if (userDetails?.id) {
            let bookingDetails;
            if (serviceDetails.mode === "QUEUE") {
                let prepareQueuePayload = {
                    customerId: userDetails.id,
                    startSec: moment(showUserFormData?.selectedSlot?.startSec * 1000).startOf('day').unix(),
                    tempServiceId: showUserFormData?.scheduleData?.id,
                    toSec: moment(showUserFormData?.selectedSlot?.startSec * 1000).endOf('day').unix(),
                }
                const waitListDetails = await prepareQueue(prepareQueuePayload);
                if (waitListDetails?.availabilityId) {
                    let queuePayload = {
                        customerId: waitListDetails?.customerId ?? userDetails?.id,
                        productId: orgDetails?.preferences?.enableProducts && productDetails?.id ? productDetails?.id : null,
                        duration: showUserFormData?.scheduleData?.avgServiceTime,
                        availabilityId: waitListDetails?.availabilityId,
                        startSec: waitListDetails?.startSec,
                        status: "UNSPECIFIED",
                        type: "APPOINTMENT",
                        responseSurveys: showUserFormData?.scheduleData?.surveyId ? assessmentData : {}
                    }
                    bookingDetails = await createQueue(queuePayload);
                }
            } else {
                let bookingPayload = {
                    customerId: userDetails.id,
                    productId: orgDetails?.preferences?.enableProducts && productDetails?.id ? productDetails?.id : null,
                    duration: showUserFormData?.scheduleData?.avgServiceTime,
                    availabilityId: showUserFormData?.selectedSlot?.id,
                    startSec: showUserFormData?.selectedSlot?.startSec,
                    status: "UNSPECIFIED",
                    type: "APPOINTMENT",
                    responseSurveys: showUserFormData?.scheduleData?.surveyId ? assessmentData : {}
                }
                bookingDetails = await createAppointment(bookingPayload);
            }
            if (bookingDetails?.id) {
                dispatch(setShowUserForm(false));
                dispatch(setAssesmentData({}));
                navigate(`/event/${bookingDetails?.id}`);
            }
        }
    }

    const confirmOTP = async () => {
        setIsLoading(true);
        const userDetails = await dispatch(confirmGuestPhoneNumberOtp(otpFormData?.userDetails?.telephone, values.otp));
        if (userDetails?.id) {
            await completeBookingProcess(userDetails);
            setOtpFormData(null);
        }
        setIsLoading(false);
    }

    const { values, handleChange, handleBlur, handleSubmit, isSubmitting, errors, touched, setFieldValue, setFieldError } = useFormik({
        initialValues: {
            customerName: userData?.givenName ?? '',
            customerPhone: userData?.telephone ?? '',
            customerEmail: userData?.email ?? '',
            acceptTerms: userData?.id ? true : false,
            otp: ""
        },
        validationSchema: userFormSchema,
        enableReinitialize: true,
        onSubmit: async values => {
            try {
                if (!values?.customerEmail && !values.customerPhone) {
                    setFieldError("customerPhone", "Email or Phone number is required");
                    return;
                }
                let userPayload = {
                    email: values.customerEmail,
                    givenName: values.customerName,
                    phone: values.customerPhone,
                    tmpServiceId: showUserFormData?.scheduleData?.id,
                    userType: "GUEST"
                }
                setIsLoading(true);
                const userDetails = userData.id && userData.email === values.customerEmail && userData.telephone === values.customerPhone ? userData : await createGuetUser(userPayload);
                if (userDetails.userType === "GUEST" && !userDetails.isPhoneVerified && userDetails.otpSend) {
                    setOtpFormData({ userDetails }); //showsOTP form
                } else {
                    await completeBookingProcess(userDetails);
                }
                setIsLoading(false);
            } catch (e) {
                setIsLoading(false);
                console.log(e)
            }
        },
    });

    const isSameValue = (item1, item2) => {
        let out = false;
        if (item1 === item2)
            out = true
        else if (!item1 && !item2)
            out = true;
        else
            out = false;

        //console.log("isSame", out, item1, item2)
        return out;
    }


    return (
        <>

            <Recaptcha
                ref={recaptchaRef}
                sitekey={GOOGLE_CAPTCHA_SITE_KEY}
                onResolved={(e) => {
                    setCaptchaResolved(true);
                    console.log("captch resolved", e);
                }}
                style={{ position: "absolute", top: 0 }}
            />
            <SwipeableDrawer
                anchor="bottom"
                open={openDrawer}
                onClose={toggleDrawer(false)}
                onOpen={toggleDrawer(true)}
                swipeAreaWidth={56}
                disableSwipeToOpen={true}
                ModalProps={{
                    keepMounted: true,
                }}
                classes={{ paperAnchorBottom: "rounded-t-2xl" }}
            >
                {!otpFormData ?
                    <Box sx={{ minHeight: height * .8, p: 1 }}>
                        <Puller />
                        <Typography sx={{ p: 3, color: 'text.secondary', textAlign: 'center' }}>Please provide a few details to proceed</Typography>
                        <GridContainer justifyContent="center" alignItems="center" direction="row" >
                            <GridItem xs={12}>
                                <Box sx={{ maxWidth: 567, mx: 'auto', my: 2 }}>
                                    <TextField
                                        label="Name"
                                        variant="filled"
                                        fullWidth
                                        name="customerName"
                                        onChange={handleChange}
                                        value={values.customerName}
                                        helperText={touched?.customerName && errors?.customerName}
                                        error={touched?.customerName && errors?.customerName ? true : false}
                                        disabled={userData?.id && isSameValue(userData?.email, values?.customerEmail) &&
                                            isSameValue(userData?.telephone, values?.customerPhone) ?
                                            true : false
                                        }
                                    />
                                </Box>
                                <Box sx={{ maxWidth: 567, mx: 'auto', my: 2 }}>
                                    <PhoneNumberInput
                                        label="Phone Number"
                                        variant="filled"
                                        fullWidth
                                        name="customerPhone"
                                        onChange={(phone) => setFieldValue("customerPhone", phone)}
                                        value={values.customerPhone}
                                        error={touched?.customerPhone && errors?.customerPhone ? true : false}
                                        helperText={touched?.customerPhone && errors?.customerPhone}
                                    />
                                </Box>
                                <Box sx={{ maxWidth: 567, mx: 'auto' }}>
                                    <Divider> OR </Divider>
                                </Box>
                                <Box sx={{ maxWidth: 567, mx: 'auto', my: 2 }}>
                                    <TextField
                                        label="Email"
                                        variant="filled"
                                        fullWidth
                                        name="customerEmail"
                                        onChange={handleChange}
                                        value={values.customerEmail}
                                        error={touched?.customerEmail && errors?.customerEmail ? true : false}
                                        helperText={touched?.customerEmail && errors?.customerEmail}
                                        type="email"
                                        autoComplete="off"
                                    />
                                </Box>
                                {!userData?.id ?
                                    <Box sx={{ maxWidth: 567, mx: 'auto', my: 2 }}>
                                        <FormControlLabel
                                            control={<Checkbox checked={values.acceptTerms} />}
                                            name="acceptTerms"
                                            label={
                                                <span>
                                                    I agree to the <Link onClick={() => setOpenPopup(true)}>Terms &amp; Conditions</Link>
                                                </span>
                                            }
                                            onChange={handleChange} />

                                        <ScrollDialog
                                            show={openPopup}
                                            onClose={() => setOpenPopup(false)}
                                            title=""
                                            content={orgDetails?.privacyStatement ? parseHtml(orgDetails?.privacyStatement) : <TermsContent />}
                                        />

                                    </Box>
                                    : null
                                }
                                <Box sx={{ maxWidth: 567, mx: 'auto', my: 3, display: 'flex', justifyContent: 'center' }}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleSubmit}
                                        disabled={isLoading || !captchaResolved || !values.acceptTerms}
                                        endIcon={isLoading ? <CircularProgress size={20} color="inherit" /> : <DoubleArrow />}
                                    >
                                        Book Now
                                    </Button>
                                </Box>
                                {!userData?.id ?
                                    <Box sx={{ maxWidth: 567, mx: 'auto', mt: 5 }}>
                                        <Divider>Already have a registered account? </Divider>
                                        <Box sx={{ maxWidth: 567, mx: 'auto', my: 2, display: 'flex', justifyContent: 'center' }}>
                                            <Button
                                                variant="outlined"
                                                color="primary"
                                                onClick={() => dispatch(setShowLoginForm(true))}
                                                disabled={isLoading}
                                            >
                                                Sign In with existing account
                                            </Button>
                                        </Box>
                                    </Box>
                                    : null
                                }
                            </GridItem>
                        </GridContainer>
                    </Box>
                    :
                    <Box sx={{ minHeight: height * .5, p: 1 }}>
                        <Puller />
                        <Typography sx={{ p: 3, color: 'text.secondary', textAlign: 'center' }}>Enter OTP sent to '{otpFormData?.userDetails?.telephone ?? ''}'</Typography>
                        <GridContainer justifyContent="center" alignItems="center" direction="row" >
                            <GridItem xs={12}>
                                <Box sx={{ maxWidth: 567, mx: 'auto', my: 2 }}>
                                    <OtpInput
                                        value={values.otp}
                                        onChange={(otp) => setFieldValue("otp", otp)}
                                    />
                                </Box>
                                <Box sx={{ maxWidth: 567, mx: 'auto', my: 5, display: 'flex', justifyContent: 'center' }}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={confirmOTP}
                                        disabled={isLoading || !values.otp || values.otp.length < 6}
                                        endIcon={isLoading ? <CircularProgress size={20} color="inherit" /> : <DoubleArrow />}
                                    >
                                        Continue
                                    </Button>
                                </Box>
                            </GridItem>
                        </GridContainer>
                    </Box>

                }

            </SwipeableDrawer>
        </>
    )

}

const Puller = styled(Box)(({ theme }) => ({
    width: 30,
    height: 6,
    backgroundColor: theme.palette.mode === 'light' ? grey[300] : grey[900],
    borderRadius: 3,
    position: 'absolute',
    top: 8,
    left: 'calc(50% - 15px)',
}));


export default UserForm;