import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from "@mui/material";
import { Repeat } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import SlotPicker from "../../components/SlotPicker/SlotPicker";
import SkeletonCard from "../../components/Loaders/SkeletonCard";
import { fetchAvailabilitiesByScheduleId, resheduleBooking } from "../../redux/actions/apiActions";

const BookingReschedule = () => {
    const bookingDetails = useSelector(state => state.api.bookingDetails);
    const availabilities = useSelector(state => state?.api?.availabilities[bookingDetails?.tempServiceId]);
    const availabilitiesLoading = useSelector(state => state?.api?.availabilitiesLoading[bookingDetails?.id]);
    const dispatch = useDispatch();

    const [showRescheduleModal, setShowRescheduleModal] = useState(false);
    const [filteredAvailabilities, setFilteredAvailabilities] = useState([]);
    const [isRefreshing, setIsRefreshing] = useState(false);

    const [selectedSlot, setSelectedSlot] = useState(null);

    const onSelectSlot = (slot) => {
        setSelectedSlot(prev => prev?.startSec !== slot?.startSec ? slot : null)
    }

    const closeRescheduleModal = () => {
        setShowRescheduleModal(false);
    }
    const onConfirmReschedule = async () => {
        if (selectedSlot?.id && bookingDetails?.id) {
            let payload = {
                eventId: bookingDetails?.id,
                newAvailabilityId: selectedSlot?.id
            }
            const status = await dispatch(resheduleBooking(payload));
            if (status) {
                setShowRescheduleModal(false);
            }
        }

    }

    useEffect(() => {
        if (availabilities) {
            setIsRefreshing(true);
            let filteredAvailabilities_ = availabilities.filter(a => a.startSec !== bookingDetails.startSec);
            setFilteredAvailabilities(filteredAvailabilities_)
            setTimeout(() => setIsRefreshing(false), 500)
        }
    }, [availabilities])

    useEffect(() => {
        if (showRescheduleModal) {
            setIsRefreshing(true);
            dispatch(fetchAvailabilitiesByScheduleId(bookingDetails?.tempServiceId))
            //setTimeout(() => setIsRefreshing(false), 1000)
        }
    }, [showRescheduleModal])

    if (!bookingDetails?.id) {
        return <></>
    }
    return (
        <>
            <Button variant="outlined" sx={{ borderRadius: 5 }} onClick={() => setShowRescheduleModal(true)}>
                <Repeat />  Reschedule
            </Button>
            <Dialog
                open={showRescheduleModal}
                onClose={closeRescheduleModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{ sx: { borderRadius: 3, minHeight: '60%' } }}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle id="alert-dialog-title" sx={{ textAlign: 'center' }}>
                    <Typography variant="overline" >Reschedule Booking</Typography>
                </DialogTitle>
                <DialogContent>
                    {availabilitiesLoading || isRefreshing ?
                        <SkeletonCard cardType="availabilities" />
                        :
                        <SlotPicker
                            availabilities={filteredAvailabilities}
                            onSelectSlot={onSelectSlot}
                            selectedSlot={selectedSlot}
                            timezoneId={bookingDetails?.timezoneId}
                        />
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeRescheduleModal}>Cancel</Button>
                    <Button onClick={onConfirmReschedule} autoFocus disabled={!selectedSlot}>
                        Reschedule
                    </Button>
                </DialogActions>
            </Dialog>
        </>


    )
}

export default BookingReschedule;