import { Clear, Repeat } from "@mui/icons-material";
import { Button, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import ConfirmationDialog from "../../components/Dialog/ConfirmationDialog";
import { useState } from "react";
import { cancelBooking } from "../../redux/actions/apiActions";

const BookingCancel = () => {

    const [showDialog, setShowDialog] = useState(false);
    const bookingDetails = useSelector(state => state.api.bookingDetails);
    const dispatch = useDispatch();

    const onCloseDialog = () => {
        setShowDialog(false);
    }
    const onConfirmDialog = () => {
        setShowDialog(false);
        if (bookingDetails?.id) {
            dispatch(cancelBooking(bookingDetails?.id));
        }
    }

    return (
        <>
            <Button variant="outlined" sx={{ borderRadius: 5 }} onClick={() => setShowDialog(true)}>
                <Clear />  Cancel
            </Button>
            <ConfirmationDialog
                show={showDialog}
                title={<Typography variant="overline">Booking Cancellation</Typography>}
                content="Do you want to cancel the booking ?"
                onClose={onCloseDialog}
                onConfirm={onConfirmDialog}
                onCancel={onCloseDialog}
            />
        </>
    )
}

export default BookingCancel;