import { useEffect, useState } from "react";
import { Button, Box, Chip, IconButton, Alert, Divider } from "@mui/material";
import GridContainer from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem";
import moment from "moment-timezone";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import ButtonDatePicker from "../ButtonDatePicker/ButtonDatePicker";
import { NavigateBefore, NavigateNext } from "@mui/icons-material";

const SlotPicker = ({ availabilities, timezoneId = "Asia/Kolkata", selectedSlot = {}, onSelectSlot = () => { }, onJoinQueue = () => { }, mode = "SCHEDULE" }) => {


    const [displayedSlots, setDisplayedSlots] = useState([]);
    const [selectedDate, setSelectedDate] = useState(moment.tz(timezoneId));
    const [isProcessing, setIsProcessing] = useState(true);

    const [slotsPaginationIndex, setSlotsPaginationIndex] = useState(0)
    const [slotsPerPage, setSlotsPerPage] = useState(12)

    const onClickSlot = (slot) => (e) => {
        if (onSelectSlot) {
            onSelectSlot(slot, e)
        }
    }
    const onClickJoinQueue = (slot) => (e) => {
        if (onJoinQueue) {
            onJoinQueue(slot, e)
        }
    }

    const handleSelectedDateChange = (date) => {
        setIsProcessing(true);
        const startDayTime = moment.tz(date, timezoneId).startOf('day')
        const endDayTime = moment.tz(date, timezoneId).endOf('day')
        const availabilitiesOnSelectedDate = availabilities.filter((element) => {
            return ((startDayTime <= (element.startSec * 1000)) && ((element.startSec * 1000) <= endDayTime))
        })
        setSelectedDate(date)
        setDisplayedSlots(availabilitiesOnSelectedDate);
        setIsProcessing(false);
    }

    const navigateToNextDate = () => {
        const selectedDateNextDate = moment.tz(selectedDate, timezoneId).add(1, 'd')
        const firstDateWithAvailabilities = availabilities.find(
            availability => (availability.startSec * 1000 >= selectedDateNextDate)
        )
        if (firstDateWithAvailabilities) {
            handleSelectedDateChange(moment.tz(firstDateWithAvailabilities.startSec * 1000, timezoneId).startOf('day'))
        }
    }

    const navigateToPreviousDate = () => {
        const timeNow = moment.tz(timezoneId)
        const availabilityArray = availabilities.slice().reverse()
        const firstDateWithAvailabilities = availabilityArray.find(
            availability => ((timeNow < availability.startSec * 1000) && (availability.startSec * 1000 < selectedDate))
        )
        if (firstDateWithAvailabilities) {
            handleSelectedDateChange(moment.tz(firstDateWithAvailabilities.startSec * 1000, timezoneId).startOf('day'))
        }
    }

    useEffect(() => {
        if (availabilities && availabilities.length > 0) {
            const timeNowUnix = moment().unix();
            const firstAvailableSlot = availabilities.find(availability => (availability.startSec * 1000 > timeNowUnix));
            if (firstAvailableSlot) {
                handleSelectedDateChange(moment.tz(firstAvailableSlot.startSec * 1000, timezoneId).startOf('day'))
            } else {
                setDisplayedSlots([]);
                setIsProcessing(false)
            }
        } else {
            setIsProcessing(false)
        }
    }, [availabilities])

    if (isProcessing) {
        return (
            <>
            </>
        )
    }

    return (
        <Box sx={{ borderColor: 'slotPicker.main', borderWidth: 1, borderRadius: 5, p: 2 }}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <GridContainer justifyContent="space-around" className="mb-5" spacing={1} >
                    <GridItem>
                        <IconButton color="slotPicker" onClick={navigateToPreviousDate}>
                            <NavigateBefore />
                        </IconButton>
                    </GridItem>
                    <GridItem>
                        <ButtonDatePicker
                            defaultValue={selectedDate}
                            className="p-0"
                            disablePast
                            value={selectedDate}
                            onChange={handleSelectedDateChange}
                            label={`${selectedDate == null ? 'Select a Date' : selectedDate.format('ddd, DD MMM YYYY')}`}
                        />
                    </GridItem>
                    <GridItem>
                        <IconButton color="slotPicker" onClick={navigateToNextDate}>
                            <NavigateNext />
                        </IconButton>
                    </GridItem>
                </GridContainer>
                <GridContainer justifyContent="center" spacing={1} >
                    {mode === "QUEUE" && displayedSlots.length ?
                        <GridItem xs={12}>
                            <Alert sx={{ justifyContent: 'center', borderRadius: 3 }} severity="info">{displayedSlots.length} slots available</Alert>
                            <Box sx={{ display: 'flex', width: '100', justifyContent: 'center' }}>
                                <Button onClick={onClickJoinQueue(displayedSlots[0])} variant="contained" sx={{ mx: 'auto', my: 3, borderRadius: 3 }}> Join Queue</Button>
                            </Box>
                        </GridItem>
                        :
                        <>
                            {mode === "BOTH_QUE_AND_SCHEDULE" && displayedSlots.length ?
                                <GridItem xs={12} sx={{ mb: 3 }}>
                                    <Alert sx={{ justifyContent: 'center', borderRadius: 3 }} severity="info">{displayedSlots.length} slots available</Alert>
                                    <Box sx={{ display: 'flex', width: '100', justifyContent: 'center' }}>
                                        <Button onClick={onClickJoinQueue(displayedSlots[0])} variant="contained" sx={{ mx: 'auto', my: 3, borderRadius: 3 }}> Join Queue</Button>
                                    </Box>
                                    <Divider>Or Pick a Time Slot</Divider>
                                </GridItem>
                                : null
                            }
                            {displayedSlots.map(slot =>
                                <GridItem key={slot.startSec}>
                                    <Chip
                                        color="slotPicker"
                                        variant={selectedSlot?.startSec === slot?.startSec ? "filled" : "outlined"}
                                        label={moment(slot.startSec * 1000).tz(timezoneId).format("h:mm a")}
                                        onClick={onClickSlot(slot)}
                                        sx={{ minWidth: 80 }}
                                    />
                                </GridItem>
                            )}
                        </>
                    }
                    {
                        !availabilities?.length ?
                            <>
                                <Alert sx={{ justifyContent: 'center', borderRadius: 3 }} severity="error">No slots available for this service</Alert>
                            </>
                            : !displayedSlots?.length ?
                                <>
                                    <Alert sx={{ justifyContent: 'center', borderRadius: 3 }} severity="error">No slots available for the selected date</Alert>
                                </>
                                :
                                <></>
                    }
                </GridContainer>
            </LocalizationProvider>
        </Box>
    )
}

export default SlotPicker;
