
import { useTheme, ThemeProvider, createTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, { useEffect } from 'react';
import { themes } from '../config/themes';
import { useDispatch, useSelector } from 'react-redux';
import { setThemeMode } from '../redux/actions/themeActions';
import { CssBaseline } from '@mui/material';

const ThemeWrapper = ({ children }) => {

    const themeMode = useSelector(state => state.theme.mode);
    const dispatch = useDispatch();
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

    useEffect(() => {
        if(!themeMode){
            dispatch(setThemeMode(prefersDarkMode ? 'dark' : 'light'));
        }
    }, [prefersDarkMode])

    const theme = React.useMemo(
        () => {
            let themeConfig = themes[themeMode];
            return createTheme(themeConfig);
        },
        [themeMode],
    );

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline/>
            {children}
        </ThemeProvider>
    )
}

export default ThemeWrapper;